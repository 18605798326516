import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { usePapaParse } from "react-papaparse";
import BTEIcon from "../../assets/images/betero-icon.svg";
import BettingVolumeIcon from "../../assets/images/betting-volume-icon.svg";
import ProfitIcon from "../../assets/images/profit-icon.svg";
import GoldenBadge from "../../assets/images/gold.svg";
import SilverBadge from "../../assets/images/silver.svg";
import BronzeBadge from "../../assets/images/bronze.svg";
import WoodenBadge from "../../assets/images/wooden.svg";
import Button from "../Button";
import Spinner from "../Spinner";
import { PLAYER_DATA, PORTAL_DATA } from "../../services/config";
import { parseBNumber, readableNumber } from "../../services/utils";
import {
  CONTRACT_BTE_CASHBACK,
  CONTRACT_BTE_GOVERNANCE,
  DECIMALS,
} from "../../abis/constants";
import { useTokenPrice } from "../../hooks";
import { useWeb3React } from "@web3-react/core";
import { Contract } from "ethers";

const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const PlayerHolding = () => {
  const { account, provider } = useWeb3React();
  const { getBTEPrice } = useTokenPrice();
  const { locale } = useSelector((state) => state.common);
  const { readRemoteFile } = usePapaParse();
  const nextMonth = ((new Date().getMonth() + 1) % 12) + 1;
  const [loading, setLoading] = useState(false);
  const [btePrice, setBtePrice] = useState(0);
  const [bteHoldings, setBTEHoldings] = useState(0);
  const [bteClaimableCashback, setBTEClaimableCashback] = useState(0);
  const [portalData, setPortalData] = useState({
    currentMonth: "",
    currentMonthUSD: "",
    currentMonthBTE: "",
    prevMonth: "",
    prevMonthUSD: "",
    prevMonthBTE: "",
    tierData: {
      0: {
        name: "",
        threshold: 0,
        benefit: "",
        multiplier: 1,
        badge: WoodenBadge,
      },
      1: {
        name: "",
        threshold: 30000,
        benefit: "",
        multiplier: 2,
        badge: BronzeBadge,
      },
      2: {
        name: "",
        threshold: 150000,
        benefit: "",
        multiplier: 3,
        badge: SilverBadge,
      },
      3: {
        name: "",
        threshold: 300000,
        benefit: "",
        multiplier: 4,
        badge: GoldenBadge,
      },
    },
  });
  const [playerData, setPlayerData] = useState({});
  const tierIndex = useMemo(() => {
    if (bteHoldings < portalData.tierData[1].threshold) return 0;
    if (bteHoldings < portalData.tierData[2].threshold) return 1;
    if (bteHoldings < portalData.tierData[3].threshold) return 2;
    return 3;
  }, [bteHoldings, portalData]);
  const tierData = portalData.tierData[tierIndex];

  const getPlayerInfo = useCallback(async () => {
    const bte_price = await getBTEPrice();
    setBtePrice(bte_price);
    if (!account || !provider) {
      setBTEHoldings(0);
      return;
    }
    const signer = provider.getSigner(account);
    const governanceContract = new Contract(
      CONTRACT_BTE_GOVERNANCE.address,
      CONTRACT_BTE_GOVERNANCE.abi,
      signer
    );
    const votingPower = await governanceContract.calculateVotingPower(account);
    const holdingsBTE = parseBNumber(votingPower, DECIMALS);
    setBTEHoldings(holdingsBTE);
    const cashbackContract = new Contract(
      CONTRACT_BTE_CASHBACK.address,
      CONTRACT_BTE_CASHBACK.abi,
      signer
    );
    const claimableAmount = await cashbackContract.claimableAmount(account);
    const claimableCashbackBTE = parseBNumber(claimableAmount, DECIMALS);
    setBTEClaimableCashback(claimableCashbackBTE);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, provider]);

  const getPortalData = useCallback(() => {
    readRemoteFile(PORTAL_DATA, {
      complete: (results) => {
        const _result = results.data;
        if (_result && _result.length > 22) {
          const currentMonth = _result[1][1];
          const currentMonthUSD = _result[2][1];
          const currentMonthBTE = _result[3][1];
          const prevMonth = _result[4][1];
          const prevMonthUSD = _result[5][1];
          const prevMonthBTE = _result[6][1];
          const tierData = {
            0: {
              name: _result[19][0],
              threshold: Number(_result[19][1].replace(",", "")),
              benefit: _result[19][2],
              multiplier: _result[19][3],
              badge: WoodenBadge,
            },
            1: {
              name: _result[20][0],
              threshold: Number(_result[20][1].replace(",", "")),
              benefit: _result[20][2],
              multiplier: _result[20][3],
              badge: BronzeBadge,
            },
            2: {
              name: _result[21][0],
              threshold: Number(_result[21][1].replace(",", "")),
              benefit: _result[21][2],
              multiplier: _result[21][3],
              badge: SilverBadge,
            },
            3: {
              name: _result[22][0],
              threshold: Number(_result[22][1].replace(",", "")),
              benefit: _result[22][2],
              multiplier: _result[22][3],
              badge: GoldenBadge,
            },
          };
          setPortalData({
            currentMonth,
            currentMonthUSD,
            currentMonthBTE,
            prevMonth,
            prevMonthUSD,
            prevMonthBTE,
            tierData,
          });
        }
      },
    });
    if (!account) return;
    readRemoteFile(PLAYER_DATA, {
      complete: (results) => {
        const _result = results.data;
        if (_result) {
          for (let item of _result) {
            if (item[1]?.toLowerCase() === account.toLowerCase()) {
              setPlayerData({
                nickname: item[0],
                bet: Number(item[2]),
                pnl: Number(item[5]),
              });
              break;
            }
          }
        }
      },
    });
  }, [readRemoteFile, account]);

  const getPlayerData = useCallback(() => {
    if (!account) {
      setPlayerData({});
      return;
    }
    readRemoteFile(PLAYER_DATA, {
      complete: (results) => {
        const _result = results.data;
        if (_result) {
          for (let item of _result) {
            if (item[1]?.toLowerCase() === account.toLowerCase()) {
              setPlayerData({
                nickname: item[0],
                bet: Number(item[2]),
                pnl: Number(item[5]),
              });
              break;
            }
          }
        }
      },
    });
  }, [account, readRemoteFile]);

  useEffect(() => {
    getPlayerInfo();
    getPortalData();
    getPlayerData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClaim = async () => {
    setLoading(true);
    try {
      const cashbackContract = new Contract(
        CONTRACT_BTE_CASHBACK.address,
        CONTRACT_BTE_CASHBACK.abi,
        provider.getSigner(account)
      );
      await cashbackContract.claim();
      const claimableAmount = await cashbackContract.claimableAmount(account);
      const claimableCashbackBTE = parseBNumber(claimableAmount, DECIMALS);
      setBTEClaimableCashback(claimableCashbackBTE);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  return (
    <div className="mb-4 grid grid-cols-1 md:grid-cols-3 divide-divider divide-y md:divide-y-0 divide-x-0 md:divide-x px-4 md:px-0 py-0 md:py-5 bg-secondary rounded-xl">
      <div className="px-0 md:px-6 py-4 md:py-0">
        <p className="text-15 text-white font-medium">
          {locale["welcome"]}, {playerData.nickname ?? "Player"}
        </p>
        <div className="mt-2.5 grid grid-cols-2 md:grid-cols-1 gap-0 md:gap-4">
          <div className="flex items-center mt-2">
            <div className="flex items-center justify-center w-10 h-10 bg-primary rounded-full">
              <img src={BettingVolumeIcon} alt="" className="w-7 h-7" />
            </div>
            <div className="ml-2">
              <h5 className="text-19 font-medium leading-none">
                ${readableNumber((playerData.bet ?? 0).toFixed(2))}
              </h5>
              <p className="text-12 text-gray-light">
                {locale["betting_volume"]}
              </p>
            </div>
          </div>
          <div className="flex items-center mt-2">
            <div className="flex items-center justify-center w-10 h-10 bg-primary rounded-full">
              <img src={ProfitIcon} alt="" className="w-6.5 h-6.5" />
            </div>
            <div className="ml-2">
              <h5
                className={`text-19 ${
                  playerData.pnl > 0 ? "text-green-primary" : "text-gray-light"
                } font-medium leading-none`}
              >
                {playerData.pnl < 0 && "-"}$
                {readableNumber(Math.abs(playerData.pnl ?? 0).toFixed(2))}
              </h5>
              <p className="text-12 text-gray-light">
                {portalData.currentMonth}{" "}
                {playerData.pnl > 0 ? locale["profit"] : locale["loss"]}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="px-0 md:px-6 py-4 md:py-0">
        <p className="text-15 text-gray-light font-medium">
          {locale["your_holdings"]}
        </p>
        <div className="mt-2.5">
          <div className="flex items-center mt-2">
            <div className="w-9 h-9 rounded-full bg-primary flex items-center justify-center">
              <img src={tierData.badge} alt="" className="w-3 h-5 md:h-9" />
            </div>
            <div className="ml-2">
              <h5 className="text-19 text-white font-medium leading-none">
                {readableNumber(bteHoldings?.toFixed(2))} BTE
              </h5>
              <p className="text-12 text-gray-light">
                ${readableNumber((bteHoldings * btePrice)?.toFixed(2))}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <p className="text-15 text-gray-light font-medium">
            {tierData.name} Tier Benefits
          </p>
          <p className="text-15 text-white font-medium">{tierData.benefit}</p>
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-1 px-0 md:px-6 py-4 md:py-0 gap-0 md:gap-4">
        <div className="gap-0 md:gap-4">
          <p className="text-15 text-gray-light font-medium">
            {locale["your_cashback"]}
          </p>
          <div className="mt-2 flex items-center">
            <img src={BTEIcon} alt="" className="w-38px h-38px" />
            <div className="ml-2">
              <h5 className="text-19 text-white font-medium leading-none">
                {readableNumber(bteClaimableCashback?.toFixed(2))} BTE
              </h5>
              <p className="text-12 text-gray-light">
                ${readableNumber((bteClaimableCashback * btePrice)?.toFixed(2))}
              </p>
            </div>
          </div>
        </div>
        <div className="w-auto md:w-full flex flex-col items-center justify-center">
          <Button
            type={bteClaimableCashback > 0 ? "contained" : "disabled"}
            className="h-10"
            onClick={handleClaim}
          >
            {loading ? (
              <Spinner />
            ) : bteClaimableCashback > 0 ? (
              <span>{locale["claim"]}</span>
            ) : (
              <div className="flex flex-col items-center">
                <p className="text-14 leading-none">{locale["no_cashback"]}</p>
                <p className="text-8">{locale["no_cashback_desc"]}</p>
              </div>
            )}
          </Button>
          <p className="mt-1 text-12 text-gray-light font-medium">
            expires 1st {Months[nextMonth - 1]}
          </p>
        </div>
      </div>
    </div>
  );
};
