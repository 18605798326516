import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import { Contract } from "ethers";

// import ArrowUpIcon from '../../assets/images/arrow-up.png';
import BTEIcon from "../../assets/images/betero-icon.svg";
import BTENetwork from "../../assets/images/bte-network.png";
import { CONTRACT_BTE_GOVERNANCE } from "../../abis/constants";
import { parseBNumber, readableNumber } from "../../services/utils";
import BuyBTEPopup from "../BuyBTEPopup";
import { DECIMALS } from "../../abis/constants";
import { useTokenPrice } from "../../hooks";
// import WalletPopup from '../WalletPopup';

export const VoteHeader = () => {
  const { chainId, account, provider } = useWeb3React();
  const { getBTEPrice } = useTokenPrice();
  const { locale } = useSelector((state) => state.common);
  const [btePrice, setBtePrice] = useState(0);
  const [bteVotingPower, setBTEVotingPower] = useState(0);
  const [openBuyPopup, setOpenBuyPopup] = useState(false);
  // const [openWalletPopup, setOpenWalletPopup] = useState(false);

  const initProcess = useCallback(async () => {
    const bte_price = await getBTEPrice();
    setBtePrice(bte_price);

    if (chainId !== 56 || !account || !provider) {
      return;
    }
    const governanceContract = new Contract(
      CONTRACT_BTE_GOVERNANCE.address,
      CONTRACT_BTE_GOVERNANCE.abi,
      provider
    );
    const votingPower = await governanceContract.calculateVotingPower(account);
    const votingPowerBTE = parseBNumber(votingPower, DECIMALS);
    setBTEVotingPower(votingPowerBTE);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, account, provider]);

  useEffect(() => {
    initProcess();
  }, [initProcess]);

  useEffect(() => {
    const interval = setInterval(() => {
      initProcess();
    }, 10000);

    return () => clearInterval(interval);
  }, [initProcess]);

  const handleClick = () => {
    setOpenBuyPopup(true);
  };

  return (
    <>
      <div>
        <div className="pt-4 flex justify-between items-end">
          <div>
            <h3 className="text-19 font-bold">
              {locale["betero_dao_governance1"]}
            </h3>
            <p className="text-15 pt-1">
              {locale["betero_dao_governance1_desc"]}
            </p>
          </div>
          <div
            className="w-48 md:w-auto h-50px p-1 rounded-full border border-green-primary bg-green-overlay cursor-pointer flex items-center"
            onClick={() => handleClick()}
          >
            <img src={BTEIcon} alt="" className="w-38px h-38px" />
            <div className="w-full flex justify-center md:px-4">
              <span className="text-14 font-montserrat">
                {locale["buy"]} <span className="font-bold">BTE</span>
              </span>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 pt-5">
          <div className="flex items-center justify-between bg-secondary rounded-xl">
            <div className="px-4 md:px-6 py-3 md:py-5">
              <p className="text-15 text-gray-light font-medium">
                {locale["bte_price"]}
              </p>
              <div className="flex">
                <h2 className="text-30 mr-2">${btePrice.toFixed(4)}</h2>
              </div>
            </div>
            <img src={BTENetwork} alt="" className="w-44" />
          </div>
          <div className="px-4 md:px-6 py-3 md:py-5 bg-secondary rounded-xl">
            <p className="text-15 text-gray-light font-medium">
              {locale["your_voting_power"]}
            </p>
            <div className="flex items-center mt-2.5">
              <img src={BTEIcon} alt="" className="w-6 md:w-9 h-6 md:h-9" />
              <div className="ml-2">
                <h5 className="text-12 md:text-19 font-medium leading-none">
                  {readableNumber(bteVotingPower.toFixed(2))} BTE
                </h5>
                <p className="text-10 md:text-12 text-gray-light">
                  ${readableNumber((bteVotingPower * btePrice).toFixed(2))}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {openBuyPopup && <BuyBTEPopup setOpenPopup={setOpenBuyPopup} />}
    </>
  );
};
