import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Contract } from "ethers";
import { MulticallWrapper } from "ethers-multicall-provider";
import { useWeb3React } from "@web3-react/core";

import SelectNetwork from "../../components/SelectNetwork";
import TokenItem from "../../components/TokenItem";
import { FarmHeader, NewFarmHeader } from "../../components/FarmHeader";
import ConnectWallet from "../../components/ConnectWallet";
import BuyBTEPopup from "../../components/BuyBTEPopup";
import { PlayerHolding } from "../../components/PlayerHolding";
import {
  BSCTEST_FARM_CONTRACTS,
  BSC_FARM_CONTRCTS,
  CRO_FARM_CONTRCTS,
  ETH_FARM_CONTRCTS,
} from "../../abis/constants";
import { parseBNumber } from "../../services/utils";
import { useTokenPrice } from "../../hooks";

const farmDataList = {
  56: BSC_FARM_CONTRCTS,
  97: BSCTEST_FARM_CONTRACTS,
  1: ETH_FARM_CONTRCTS,
  25: CRO_FARM_CONTRCTS,
};

const Home = () => {
  const location = useLocation();
  const { chainId, account, provider } = useWeb3React();
  const { getBTEPrice } = useTokenPrice();
  const { locale } = useSelector((state) => state.common);
  const [farmList, setFarmList] = useState();
  const [showEndedFarms, setShowEndedFarms] = useState(false);
  const [openBuyPopup, setOpenBuyPopup] = useState(false);
  const prevChainId = useRef(0);
  const isBSC = chainId === 56;

  const filteredFarmList = useMemo(() => {
    const _farmList = farmList?.[chainId];
    if (showEndedFarms) {
      return _farmList?.filter((item) => item.rewardPerBlock === 0);
    } else {
      return _farmList?.filter((item) => item.rewardPerBlock !== 0);
    }
  }, [chainId, farmList, showEndedFarms]);

  const updateFarmWithBlocks = useCallback(async () => {
    if (!chainId || !provider) return;
    prevChainId.current = chainId;
    const farmData = farmDataList[chainId];
    const rewardPerBlocksPromises = [];
    const multiCallProvider = MulticallWrapper.wrap(provider);
    for (const contract of farmData) {
      const farmContract = new Contract(
        contract.address,
        contract.abi,
        multiCallProvider
      );
      const rewardPerBlockCall = farmContract.rewardPerBlock();
      rewardPerBlocksPromises.push(rewardPerBlockCall);
    }
    try {
      const data = await Promise.all(rewardPerBlocksPromises);
      const _farmList = farmData.map((item, index) => ({
        ...item,
        rewardPerBlock: parseBNumber(data[index], 18),
      }));
      setFarmList({...farmList, [chainId]: _farmList});
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId]);

  useEffect(() => {
    updateFarmWithBlocks();
  }, [updateFarmWithBlocks]);

  useEffect(() => {
    const path = location.pathname;
    if (path === "/buybte") {
      setOpenBuyPopup(true);
    }
  }, [location.pathname]);

  return (
    <div className="w-full max-w-4xl mx-auto px-4 sm:px-6 pb-10">
      {isBSC ? <NewFarmHeader /> : <FarmHeader />}
      <div className="flex items-center justify-between">
        <div>
          <SelectNetwork />
        </div>
        <div>
          <ConnectWallet />
        </div>
      </div>
      {isBSC && <PlayerHolding />}
      <div className="pt-5 pb-12 bg-secondary rounded-xl px-4 sm:px-6 -ml-4 sm:ml-0 -mr-4 sm:mr-0">
        <div className="flex justify-between items-center pb-2">
          <div>
            <h3 className="hidden md:block text-15 text-white font-medium">
              {isBSC
                ? locale["betero_platform_staking_rewards"]
                : locale["our_farms"]}
            </h3>
            <h3 className="md:hidden text-15 text-gray-light font-medium">
              {locale["our_farms"]}
            </h3>
            {isBSC && (
              <p className="hidden md:block text-12 font-medium text-gray-light cursor-pointer">
                {locale["betero_platform_staking_rewards_desc"]}
              </p>
            )}
          </div>
          <div className="flex items-center gap-0.5">
            <span className="text-15 font-medium text-green-primary invisible md:visible">
              {">"}
            </span>
            <span
              className="text-12 font-medium text-green-primary cursor-pointer"
              onClick={() => setShowEndedFarms((prev) => !prev)}
            >
              {showEndedFarms ? locale["active_farms"] : locale["ended_farms"]}
            </span>
          </div>
        </div>
        {filteredFarmList?.map((item, idx) => (
          <div key={idx}>
            <TokenItem
              contract={item}
              chainId={chainId}
              account={account}
              provider={provider}
              getBTEPrice={getBTEPrice}
            />
          </div>
        ))}
      </div>
      {openBuyPopup && <BuyBTEPopup setOpenPopup={setOpenBuyPopup} />}
    </div>
  );
};

export default Home;
