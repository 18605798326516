import { Contract } from "ethers";
import { useWeb3React } from "@web3-react/core";

import { parseBNumber } from "../services/utils";
import {
  CONTRACT_BNB_BUSD,
  CONTRACT_BNB_BTE,
  CONTRACT_ETH_USDC,
  CONTRACT_ETH_BTE,
  CONTRACT_CRO_USDC,
  CONTRACT_CRO_BTE,
} from "../abis/constants";
import { useCallback } from "react";

export const useTokenPrice = () => {
  const {chainId, provider} = useWeb3React();

  const getBNBPrice = useCallback(async () => {
    if (!provider) return 0;
    const bnbContract = new Contract(
      CONTRACT_BNB_BUSD.address,
      CONTRACT_BNB_BUSD.abi,
      provider
    );
    const balanceInfo = await bnbContract.getReserves();
    const busdAmount = parseBNumber(balanceInfo._reserve1, 18);
    const bnbAmount = parseBNumber(balanceInfo._reserve0, 18);
    const bnbPrice = busdAmount / bnbAmount;

    return bnbPrice;
  }, [provider]);

  const getETHPrice = useCallback(async () => {
    if (!provider) return 0;
    const ethContract = new Contract(
      CONTRACT_ETH_USDC.address,
      CONTRACT_ETH_USDC.abi,
      provider
    );
    const balanceInfo = await ethContract.getReserves();
    const busdAmount = parseBNumber(balanceInfo._reserve0, 6);
    const ethAmount = parseBNumber(balanceInfo._reserve1, 18);
    const ethPrice = busdAmount / ethAmount;
    return ethPrice;
  }, [provider]);

  const getCROPrice = useCallback(async () => {
    if (!provider) return 0;
    const croContract = new Contract(
      CONTRACT_CRO_USDC.address,
      CONTRACT_CRO_USDC.abi,
      provider
    );
    const balanceInfo = await croContract.getReserves();
    const busdAmount = parseBNumber(balanceInfo._reserve1, 6);
    const croAmount = parseBNumber(balanceInfo._reserve0, 18);
    const croPrice = busdAmount / croAmount;

    return croPrice;
  }, [provider]);

  const getBTEPrice = useCallback(async () => {
    if (!chainId || !provider) return 0;

    const secondTokenPrice =
      chainId === 56
        ? await getBNBPrice()
        : chainId === 1
        ? await getETHPrice()
        : chainId === 25
        ? await getCROPrice()
        : 0;
    const lpTokens = {
      56: CONTRACT_BNB_BTE,
      1: CONTRACT_ETH_BTE,
      25: CONTRACT_CRO_BTE,
    };
    const lpTokenContract = new Contract(
      lpTokens[chainId].address,
      lpTokens[chainId].abi,
      provider
    );
    const tokenInfo = await lpTokenContract.getReserves();
    const secondToken = parseBNumber(tokenInfo._reserve1, 18);
    const bte = parseBNumber(tokenInfo._reserve0, 18);
    const btePrice = (secondToken * secondTokenPrice) / bte;

    return btePrice;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, provider]);

  return {getBNBPrice, getETHPrice, getCROPrice, getBTEPrice};
};
