import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../assets/images/logo.svg';
import Button from '../Button';
import MobileMenu from './MobileMenu';
import SelectCountry from './SelectCountry';
import SelectNetwork from '../SelectNetwork';
import ConnectWallet from '../ConnectWallet';

const Header = (props) => {
    const location = useLocation();
    const isMigration = location.pathname === '/migration';

    return (
        <div className='relative flex justify-between items-center px-6.5 md:px-15 py-6 md:py-10 z-30'>
            <Link to='/'>
                <img src={Logo} alt="logo" className='w-106px md:w-150px' />
            </Link>
            <div className='hidden md:flex items-center text-15'>
                {isMigration ?
                    <>
                        <div className='mx-1'>
                            <SelectNetwork />
                        </div>
                        <div className='mx-1'>
                            <ConnectWallet />
                        </div>
                    </> :

                    (
                        <>
                            <Link to='/' className='text-green-primary border-b border-green-primary py-1 mx-5'>
                                Farm
                            </Link>
                            <Link to='/migration' className='text-gray-light py-1 mx-5 hover:text-white'>
                                Migration
                            </Link>
                            <a href='http://docs.betero.io/' target={'_blank'} rel="noreferrer" className='text-gray-light py-1 mx-5 hover:text-white'>
                                FAQ
                            </a>
                            <a href='https://app.betero.io/' target={'_blank'} rel="noreferrer" className='text-gray-light py-1 mx-5 hover:text-white'>
                                App
                            </a>
                            {/* <a href='https://www.betero.io/Betero_Litepaper.pdf' target={'_blank'} rel="noreferrer" className='text-gray-light py-1 mx-5 hover:text-white'>
                                Litepaper
                            </a> */}
                            <Button
                                type='contained'
                                className='h-11 mx-5 w-[9rem]'
                                url='https://app.betero.io/'
                            >
                                Play Now
                            </Button>
                            <div className='ml-2 pl-3 border-l border-gray-dark'>
                                <SelectCountry />
                            </div>
                        </>
                    )

                }
            </div>
            <div className='block md:hidden'>
                <MobileMenu />
            </div>
        </div>
    )
}

export default Header;
