import React from 'react';
import classNames from 'classnames';
import BteIcon from '../../assets/images/betero-icon.svg';
import BnbIcon from '../../assets/images/bnb-icon-new.svg';
import EthIcon from '../../assets/images/eth-token.png';
import CroIcon from '../../assets/images/cronos-pair.svg';
import { getVestingFormat, readableNumber } from '../../services/utils';
import { useSelector } from 'react-redux';

const TokenMainInfo = ({
    contract,
    vesting,
    userStakedToken,
    userEarnedBTE,
    apy,
    lpPrice,
    btePrice,
    onClick
}) => {
    const { locale } = useSelector(state => state.common);
    const tokenPrice = contract.type === 'MULTIPLE' ? lpPrice: btePrice;
    const ICONLIST = {
        'BNB': BnbIcon,
        'ETH': EthIcon,
        'CRO': CroIcon
    }

    return (
        <div>
            <div
                className={classNames(
                    'flex items-center justify-between',
                    {'cursor-pointer border-b-2 border-gray-dark py-5 md:py-6': onClick}
                )}
                onClick={() => onClick ? onClick(true) : {}}
            >
                <div className='flex items-center w-48 md:w-40'>
                    {contract.type === 'MULTIPLE' ? (
                        <div className='flex w-16 md:w-20'>
                            <img src={BteIcon} alt="" className='w-7 h-7 mb-2.5 z-10' />
                            <img
                                src={ICONLIST[contract.secondToken]}
                                alt=""
                                className='w-7 h-7 mt-2.5 -ml-2'
                            />
                        </div>
                    ) : (
                        <div className='w-16 md:w-20'>
                            <img src={BteIcon} alt="" className='w-34px h-34px' />
                        </div>
                    )}
                    <div className='flex-1 ml-2'>
                        <h6 className='text-12 font-bold'>{contract?.name}</h6>
                        <span className='block md:hidden text-12'>
                            {locale['vesting']} {` `}
                            <span className='font-bold'>{getVestingFormat(vesting, locale)}</span>
                        </span>
                    </div>
                </div>
                <div className='grid grid-cols-2 md:grid-cols-4 gap-4 flex-1 pl-2 sm:pl-20 md:pl-16'>
                    <div className='hidden md:block'>
                        <h6 className='text-12 font-bold'>
                            ${readableNumber((userStakedToken * tokenPrice).toFixed(2))}
                        </h6>
                        <span className='text-12'>{locale['staked']}</span>
                    </div>
                    <div className=''>
                        <h6 className='text-12 font-bold'>
                            {readableNumber(userEarnedBTE?.toFixed(2))} BTE
                        </h6>
                        <span className='text-12'>{locale['earned']}</span>
                    </div>
                    <div className=''>
                        <h6 className='text-12 font-bold'>{readableNumber(apy.toFixed(2))}%</h6>
                        <span className='text-12'>APY</span>
                    </div>
                    <div className='hidden md:block'>
                        <h6 className='text-12 font-bold'>
                            {/* {vesting > 0 ? `${vesting / 1000 / MONTH_SEC} months` : 'None'} */}
                            {getVestingFormat(vesting, locale)}
                        </h6>
                        <span className='text-12'>{locale['vesting']}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TokenMainInfo;