import React, { useEffect, useState, useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import { Contract } from "ethers";

import SelectNetwork from "../../components/SelectNetwork";
import { VoteHeader } from "../../components/FarmHeader";
import ConnectWallet from "../../components/ConnectWallet";
import { Proposal } from "../../components/Proposal";
import BTEIcon from '../../assets/images/betero-icon.svg';
import {
  CONTRACT_BTE_GOVERNANCE,
} from "../../abis/constants";

const Vote = () => {
  const {chainId, provider} = useWeb3React();
  const [currentProposal, setCurrentProposal] = useState([]);
  const isBSC = chainId === 56;

  const initProcess = useCallback(async () => {
    if (!isBSC || !provider) {
      return;
    }
    const governanceContract = new Contract(CONTRACT_BTE_GOVERNANCE.address, CONTRACT_BTE_GOVERNANCE.abi, provider);
    const _currentProposal = (await governanceContract.currentProposal()).toNumber();
    let arrays = [];
    for (let i = _currentProposal - 1 ; i >= 3 ; i--) {
      arrays.push(i);
    }
    setCurrentProposal(arrays);
  }, [isBSC, provider])

  useEffect(() => {
    initProcess();
  }, [initProcess]);

  return (
    <div className="w-full max-w-4xl mx-auto px-4 sm:px-6 pb-10">
      <VoteHeader />
      <div className="flex items-center justify-between">
        <div>
          <SelectNetwork />
        </div>
        <div>
          <ConnectWallet />
        </div>
      </div>
      {isBSC && (
        currentProposal?.map((x) => (
          <Proposal
            key={`${x}`}
            index={x + 1}
          />
        ))
      )}
      <div className='mt-8 flex justify-center'>
        <a href='https://forms.gle/Hd8sqemJBC8gFAyH6' target='_blank' rel="noreferrer">
          <div
            className='h-50px p-1 rounded-full border border-green-primary bg-green-overlay cursor-pointer flex items-center'
          >
            <img src={BTEIcon} alt="" className='w-38px h-38px' />
            <span className='text-14 px-5 font-bold'>
              Submit a Proposal
            </span>
          </div>
        </a>
      </div>        
    </div>
  );
};

export default Vote;
