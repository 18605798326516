import React from "react";
import Button from "../Button";

const NoticePopup = ({setOpenPopup}) => {
  return (
    <div className="popup-container">
      <div className="w-80 p-5 rounded-lg bg-gray-800">
        <p className="text-14 mb-10">Please switch the network to Ethereum mainnet on your Metamask.</p>
        <div className="flex items-center justify-end">
          <div className="flex mr-3">
            <Button type='contained' className='h-10' onClick={() => setOpenPopup(false)}>
              Ok
            </Button>
          </div>
          <div className="flex">
            <Button type='outlined' className='h-10' onClick={() => setOpenPopup(false)}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NoticePopup;
