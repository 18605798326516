import React, { useState } from 'react';
import { useWeb3React } from '@web3-react/core';

import BscIcon from '../../assets/images/BNBchain.svg';
import ChevronIcon from '../../assets/images/chevron-white.svg';
// import CroIcon from '../../assets/images/cro-icon.png';
import EthIcon from '../../assets/images/eth-logo.svg';
import CroIcon from '../../assets/images/cro-network.svg';
import NoticePopup from './NoticePopup';
import { networkConnection, walletConnectConnection } from '../../connectors';
import { toast } from 'react-toastify';
import { NETWORK_INFO } from '../../abis/constants';

const SelectNetwork = () => {
  const {chainId, connector} = useWeb3React();
  const networks = {
    56: {name: 'BSC', icon: BscIcon},
    // 97: {name: 'BSC', icon: BscIcon},
    1: {name: 'ETH', icon: EthIcon},
    25: {name: 'CRO', icon: CroIcon}
  };

  const [openMenu, setOpenMenu] = useState(false);
  const [openNoticePopup, setOpenNoticePopup] = useState(false);

  const handleNetwork = async (targetChainId) => {
    try {
      if (connector === walletConnectConnection.connector || connector === networkConnection.connector) {
        await connector.activate(targetChainId);
      } else {
        const _info = NETWORK_INFO[targetChainId][0];
        const addChainParameter = {
          chainId: targetChainId,
          chainName: _info?.chainName,
          rpcUrls: _info?.rpcUrl,
          nativeCurrency: _info?.nativeCurrency,
          blockExplorerUrls: _info?.blockExplorerUrls,
        };
        await connector.activate(addChainParameter);
      }
    } catch (e) {
      console.log(e);
      toast.error('Failed to switch network. Please switch the network in your wallet’s settings.');
    }
    setOpenMenu(false);
  }

  return (
    <div className='relative my-5.5 font-montserrat'>
      <div
        className='border border-green-primary rounded-full flex items-center justify-center px-4 h-50px cursor-pointer'
        onClick={() => setOpenMenu(true)}
      >
        {chainId ? (
          <>
            <img src={networks[chainId]?.['icon']} alt="" className='w-6 h-6' />
            <p className='text-14 font-bold px-2'>{networks[chainId]?.['name']}</p>
          </>
        ) : (<p className='text-14 font-bold px-2'>Select network</p>)}
        <img src={ChevronIcon} alt="" className='w-5' />
      </div>
      {openMenu && (
        <>
          <div className='z-10 bg-gray-900 absolute top-14 left-0 rounded-lg shadow overflow-hidden z-30'>
            {Object.keys(networks).map((net, index) => {
              return (
                <div
                  key={index}
                  className='flex items-center pl-4 pr-9 py-2 cursor-pointer hover:bg-green-overlay'
                  onClick={() => handleNetwork(Number(net))}
                >
                  <img src={networks[net]?.['icon']} alt="" className='w-6 h-6' />
                  <p className='text-14 px-2'>{networks[net]?.['name']}</p>
                </div>
              )
            })}
          </div>
          <div className='overlay' onClick={() => setOpenMenu(false)} />
        </>
      )}
      {openNoticePopup && (
        <NoticePopup setOpenPopup={setOpenNoticePopup} />
      )}
    </div>
  )
}

export default SelectNetwork;
