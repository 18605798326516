import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BigNumber, Contract, ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import Button from "../../components/Button";
import SelectNetwork from "../../components/SelectNetwork";
import ConnectWallet from "../../components/ConnectWallet";
import { BTE_MIGRATION_CONTRACTS } from "../../abis/constants";
import ArrowRightIcon from "../../assets/images/arrow-right-green.png";
import BTEIcon from "../../assets/images/betero-icon.svg";
import BeteroGlobe from "../../assets/images/beteroglobe.png";
import { parseBNumber } from "../../services/utils";
import { useTokenPrice } from "../../hooks";
import WalletPopup from "../../components/WalletPopup";
import MigrationModal from "./components/migrationModal";
import MigrationInputItem from "./components/migrationInputItem";

const Migration = () => {
  const { chainId, account, provider } = useWeb3React();
  const { getBTEPrice } = useTokenPrice();
  const isBSC = chainId === 56;
  const [step, setStep] = useState(0);
  const [beginMigration, setBeginMigration] = useState(false);
  const [isMax, setIsMax] = useState(false);
  const [fromAmount, setFromAmount] = useState(0);
  const [fromBigNumber, setFromBigNumber] = useState(BigNumber.from(0));
  const [fromInput, setFromInput] = useState("");
  const [toAmount, setToAmount] = useState(0);
  const [toInput, setToInput] = useState("");
  const [errorMsg, setErrorMsg] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [openWalletPopup, setOpenWalletPopup] = useState(false);
  const chain = BTE_MIGRATION_CONTRACTS[chainId];
  const [tokenData, setTokenData] = useState({
    price: 0,
    oldBteBalance: 0,
    newBteBalance: 0,
  });

  const decimals = 18;

  const approveAmount = ethers.constants.MaxUint256;

  useEffect(() => {
    const initProcess = async () => {
      if (!chainId || !provider) return;

      try {
        setLoading(true);
        if (account) {
          const token_price = await getBTEPrice();
          const oldTokenContract = new Contract(
            chain.oldTokenAddress,
            chain.oldTokenAbi,
            provider.getSigner(account)
          );
          const newTokenContract =
            isBSC &&
            new Contract(
              chain.newTokenAddress,
              chain.newTokenAbi,
              provider.getSigner(account)
            );
          const balance_old_bte = await oldTokenContract.balanceOf(account);
          const balance_new_bte = newTokenContract
            ? await newTokenContract.balanceOf(account)
            : BigNumber.from(0);
          setTokenData({
            oldBteBalance: parseBNumber(balance_old_bte, decimals),
            newBteBalance: parseBNumber(balance_new_bte, decimals),
            price: token_price,
          });
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };

    if (chainId && provider && account) initProcess();
  }, [chainId, provider, account, step, chain, isBSC, getBTEPrice]);

  useEffect(() => {
    if (beginMigration) document.body.style.overflow = "hidden";
    else document.body.style.removeProperty("overflow");
  }, [beginMigration]);

  const handleFromToken = async (fromStr) => {
    setErrorMsg(undefined);
    if (loading) return;

    const from = fromStr.replace(",", ".");
    const reg = /^\d+\.?\d*$/;

    if (fromStr === "") {
      setFromInput("");
      setFromAmount(0);
      setToInput("");
      setToAmount(0);
    } else if (reg.test(from)) {
      setFromInput(fromStr);
      setFromAmount(parseFloat(from));
      try {
        const migrationBTEContract = new Contract(
          chain.address,
          chain.abi,
          provider.getSigner(account)
        );
        const amount_in = ethers.utils.parseUnits(from, decimals);
        const amount_out_ratio = isBSC
          ? await migrationBTEContract.ratio()
          : BigNumber.from(1);
        const amount_out_parse = parseBNumber(
          amount_in.mul(amount_out_ratio),
          decimals
        );
        if (isMax) setIsMax(false);
        else setFromBigNumber(amount_in);
        setToInput(amount_out_parse.toFixed(2));
        setToAmount(amount_out_parse.toFixed(2));
      } catch (err) {
        console.log(err);
      }
    }
  };

  // const handleToToken = async (toStr) => {
  //   if (loading) return;
  //   const to = toStr.replace(",", ".");
  //   const reg = /^\d+\.?\d*$/;

  //   if (toStr === "") {
  //     setToInput("");
  //     setToAmount(0);
  //     setFromInput("");
  //     setFromAmount(0);
  //   } else if (reg.test(to)) {
  //     setToInput(toStr);
  //     setToAmount(parseFloat(to));
  //     try {
  //       const migrationBTEContract = new Contract(
  //         chain.address,
  //         chain.abi,
  //         provider.getSigner(account)
  //       );
  //       const amount_out = ethers.utils.parseUnits(to, decimals);
  //       const amount_in_ratio = isBSC
  //         ? await migrationBTEContract.ratio()
  //         : BigNumber.from(1);
  //       const amount_in_parse = parseBNumber(
  //         amount_out.div(amount_in_ratio),
  //         decimals
  //       );
  //       setFromBigNumber(amount_out.div(amount_in_ratio));
  //       setFromInput(amount_in_parse.toFixed(2));
  //       setFromAmount(amount_in_parse.toFixed(2));
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  // };

  const handleTransfer = async () => {
    if (!isBSC) {
      const transferBTEContract = new Contract(
        chain.oldTokenAddress,
        chain.oldTokenAbi,
        provider.getSigner(account)
      );
      try {
        setLoading(true);
        const transferTx = await transferBTEContract.transfer(
          chain.transferAddress,
          fromBigNumber
        );
        await transferTx.wait();
        if (transferTx) setStep(2);
      } catch (e) {
        console.error(e);
      }
      setLoading(false);
    }
  };

  const handleClose = () => {
    const curVal = fromInput;
    setFromAmount(0);
    setStep(0);
    setBeginMigration(false);
    handleFromToken(curVal);
  };

  const handleMax = async () => {
    setIsMax(true);
    const oldTokenContract = new Contract(
      chain.oldTokenAddress,
      chain.oldTokenAbi,
      provider.getSigner(account)
    );

    const curBal = await oldTokenContract.balanceOf(account);
    setFromBigNumber(curBal);
    const curBalString = ethers.utils.formatUnits(curBal, decimals);
    handleFromToken(curBalString);
  };

  const checkAllowance = async () => {
    if (fromAmount > tokenData.oldBteBalance || fromAmount <= 0.0) {
      setErrorMsg(
        fromAmount > tokenData.oldBteBalance
          ? "Insufficient balance"
          : "Old BTE Input field cannot be empty"
      );
      setTimeout(() => {
        setErrorMsg(undefined);
      }, 5000);
      return;
    }
    try {
      const contractInfo = new Contract(
        chain.oldTokenAddress,
        chain.oldTokenAbi,
        provider.getSigner(account)
      );
      const allowanceInfo = await contractInfo.allowance(
        account,
        chain.address
      );

      const decimalCoinValue = ethers.utils.parseUnits(fromInput, decimals);
      setErrorMsg(undefined);
      if (decimalCoinValue.gt(allowanceInfo)) return true;
      else return false;
    } catch (e) {
      console.log(e);
    }
  };

  const approveCoins = async () => {
    if (loading) return;
    if (!account) return;
    else
      try {
        setLoading(true);
        const oldBteContract = new Contract(
          chain.oldTokenAddress,
          chain.oldTokenAbi,
          provider.getSigner(account)
        );
        const approveTx = await oldBteContract.approve(
          chain.address,
          approveAmount
        );
        await approveTx.wait();
        if (approveTx) {
          setStep(1);
        }
      } catch (e) {
        console.error(e);
      }
    setLoading(false);
    return;
  };

  async function migrateTokens() {
    if (isBSC) {
      const migrateBteContract = new Contract(
        chain.address,
        chain.abi,
        provider.getSigner(account)
      );

      try {
        setLoading(true);
        const migrationTx = await migrateBteContract.deposit(fromBigNumber);
        await migrationTx.wait();
        if (migrationTx) setStep(2);
      } catch (e) {
        console.error(e);
        if (e.fault === "underflow" && e.operation === "parseFixed") {
          setErrorMsg("Please try again later");
          setTimeout(() => {
            setErrorMsg(undefined);
          }, 3000);
          return;
        }
      }
      setLoading(false);
      return;
    } else handleTransfer();
  }

  const handleStartMigration = async () => {
    try {
      const requireApproval = await checkAllowance();
      if (requireApproval === undefined) return;
      setStep(requireApproval && isBSC ? 0 : 1);
      setBeginMigration(true);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className=" flex flex-col items-center max-w-4xl mx-auto px-4 sm:px-6 pb-10">
      <div className="hidden max-sm:flex items-center justify-between w-full">
        <div className="mx-1">
          <SelectNetwork />
        </div>
        <div className="mx-1">
          <ConnectWallet />
        </div>
      </div>
      <div className="w-fit flex flex-col mt-[-180px] h-[50vh] max-md:h-[30vh] max-sm:m-0 max-sm:w-full max-sm:h-full z-[-1]">
        <img
          src={BeteroGlobe}
          alt=""
          className="h-[50vh] absolute w-full bg-[#63C127] top-0 left-0 right-0 max-md:h-[30vh] max-sm:hidden z-[-1]"
        />
        <h1 className="text-6xl font-semibold text-center flex justify-center items-center h-[50vh] max-md:h-[30vh] absolute w-full top-0 left-0 right-0 w-full max-md:text-4xl max-sm:relative max-sm:h-auto max-sm:justify-start max-sm:m-0 max-sm:text-xl max-sm:self-start">
          BTE Token <br className="max-sm:hidden" />
          Migration
        </h1>
      </div>
      <div className="flex justify-between items-center gap-7 mt-[-75px] max-md:flex-col max-md:mt-14 max-sm:order-1">
        <MigrationInputItem
          tokenName={"Old BTE"}
          tokenBalance={tokenData.oldBteBalance}
          tokenIcon={BTEIcon}
          value={fromInput}
          amount={fromAmount}
          price={tokenData.price}
          onChange={handleFromToken}
          onMax={handleMax}
          isMax={true}
          disabled={false}
        />
        <div className="p-4 bg-secondary rounded-xl max-md:rotate-90 shrink-0">
          <img src={ArrowRightIcon} alt="" className="w-6 h-6" />
        </div>
        <MigrationInputItem
          tokenName={"New BTE"}
          tokenBalance={tokenData.newBteBalance}
          tokenIcon={BTEIcon}
          value={toInput}
          amount={toAmount}
          price={tokenData.price}
          isMax={false}
          disabled={true}
        />
      </div>
      <Button
        type="contained"
        compact
        max
        className="flex items-center justify-center px-4 h-50px cursor-pointer w-60 mt-10 self-end max-md:self-center max-sm:order-2"
        onClick={handleStartMigration}
      >
        <span className="font-montserrat font-bold text-s">Migrate BTE</span>
      </Button>
      {errorMsg && (
        <p className="py-1 px-2 self-center transition-opacity animate-bounce font-base font-medium text-primary fixed bottom-1 bg-red-500 rounded">
          {errorMsg}
        </p>
      )}
      <div className="mt-16 w-11/12 max-sm:mt-1 max-sm:mb-5 max-sm:w-full">
        <p>
          Betero is currently transitioning to a new token smart contract.
          Holders of the original Betero token, BTE, are required to migrate
          their holdings to the updated BTE token using the interface provided
          here. This migration process maintains a one-to-one ratio, ensuring
          that each original BTE token is exchanged for an equivalent new BTE
          token.
          <br />
          <br />
          This migration window is available for a duration of 12 months,
          commencing on April 2nd, 2024.
          <br />
          <br />
          Important: The BTE token migration is not instant. You will receive
          your tokens in your wallet automatically, according to the schedule in
          our article.
          <br />
          <br />
          <Link
            to="https://medium.com/@Beterocoin"
            className="text-green-primary border-b border-green-primary"
          >
            Read our BTE Migration article here
          </Link>
        </p>
      </div>
      {beginMigration && (
        <MigrationModal
          step={step}
          loading={loading}
          approveCoins={approveCoins}
          migrateTokens={migrateTokens}
          fromInput={fromInput}
          toInput={toInput}
          handleClose={handleClose}
        />
      )}
      {openWalletPopup && <WalletPopup setOpenPopup={setOpenWalletPopup} />}
    </div>
  );
};

export default Migration;
