import React, { useCallback, useEffect, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import PuffLoader from 'react-spinners/PuffLoader';
import CloseIcon from '../../assets/images/close-circle.svg';
import BnbIcon from '../../assets/images/bnb-icon-new.svg';
import BteIcon from '../../assets/images/betero-icon.svg';
import EthIcon from '../../assets/images/eth-logo.svg';
import CroIcon from '../../assets/images/cronos-pair.svg';
import ArrowDownIcon from '../../assets/images/arrow-down.png';
import CheckIcon from '../../assets/images/check-icon.svg';
import WarningIcon from '../../assets/images/warning-icon.svg';
import Button from '../Button';
import { parseBNumber, readableNumber } from '../../services/utils';
import { BUY_BTE_CONTRACTS, CONTRACT_BNB_BTE, CONTRACT_CRO_BTE, CONTRACT_ETH_BTE } from '../../abis/constants';
import WalletPopup from '../WalletPopup';
import useAnalyticsEventTracker from '../../services/useAnalyticsEventTracker';
import { useSelector } from 'react-redux';
import { useTokenPrice } from '../../hooks';
import { useWeb3React } from '@web3-react/core';
import { Contract, ethers } from 'ethers';

const netInfo = {
  56: { name: 'BNB', label: 'Binance Smart Chain', image: BnbIcon, netId: 56 },
  1: { name: 'ETH', label: 'Ethereum mainnet', image: EthIcon, netId: 1 },
  25: { name: 'CRO', label: 'Cronos mainnet', image: CroIcon, netId: 25 },
};

const SwapAmountPanel = ({from, to, netId}) => (
  <div className='flex items-center'>
    <div className='flex items-center'>
      <img src={netInfo[netId]?.['image']} alt="" className='w-7 h-7' />
      <div className='pl-3'>
        <h5 className='text-15 font-semibold leading-none'>
          {readableNumber(from)}
        </h5>
        <p className='text-12 text-gray-light'>
          {netInfo[netId]?.['name']}
        </p>
      </div>
    </div>
    <div className='px-10'>
      <img src={ArrowDownIcon} alt="" className='w-4 h-5 transform -rotate-90'/>
    </div>
    <div className='flex items-center'>
      <img src={BteIcon} alt="" className='w-7 h-7' />
      <div className='pl-3'>
        <h5 className='text-15 font-semibold leading-none'>
          {readableNumber(to)}
        </h5>
        <p className='text-12 text-gray-light'>BTE</p>
      </div>
    </div>
  </div>
)

export default function BuyBTEPopup(props) {
  const { setOpenPopup } = props;
  const {chainId, account, provider} = useWeb3React();
  const {getETHPrice, getBNBPrice, getCROPrice} = useTokenPrice();
  const { locale } = useSelector(state => state.common);
  const [step, setStep] = useState(0);
  const [tokenPrice, setTokenPrice] = useState(0);
  const [contractInfo, setContractInfo] = useState(null);
  const [fromAmount, setFromAmount] = useState(0);
  const [fromInput, setFromInput] = useState('');
  const [amountIn, setAmountIn] = useState(0);
  const [toAmount, setToAmount] = useState(0);
  const [toInput, setToInput] = useState('');
  const [amountOut, setAmountOut] = useState(0);
  const [poolInfo, setPoolInfo] = useState({});
  const [priceImpact, setPriceImpact] = useState(0);
  // const [focusCode, setFocusCode] = useState(0); // 0 | 1
  const [loading, setLoading] = useState(false);
  const [buyLoading, setBuyLoading] = useState(false);
  const [isInBte, setIsInBte] = useState(false);
  const [balance, setBalance] = useState(0);
  const [bteBalance, setBteBalance] = useState(0);
  const [openWalletPopup, setOpenWalletPopup] = useState(false);
  const chain = BUY_BTE_CONTRACTS[chainId];
  const gaEventTracker = useAnalyticsEventTracker('Token');

  const decimals = 18;

  const initProcess = useCallback(async () => {
    if (!chainId || !provider) return;

    try {
      setLoading(true);
      setContractInfo(chain);
      if (account) {
        const balanceOf = await provider.getBalance(account)
        setBalance(parseBNumber(balanceOf, decimals));

        const tokenContract = new Contract(chain.tokenAddress, chain.tokenAbi, provider.getSigner(account));
        const balance_bte = await tokenContract.balanceOf(account);
        const bte_balance = parseBNumber(balance_bte, decimals);
        setBteBalance(bte_balance);
      }
      const token_price = chainId === 1
        ? await getETHPrice()
        : chainId === 56
          ? await getBNBPrice()
          : await getCROPrice();
      setTokenPrice(token_price);

      const lpToken = chainId === 56
        ? CONTRACT_BNB_BTE
        : chainId === 1
          ? CONTRACT_ETH_BTE
          : CONTRACT_CRO_BTE;
      const lpTokenContract = new Contract(lpToken.address, lpToken.abi, provider);
      const assets = await lpTokenContract.getReserves();
      const bteToken = parseBNumber(assets._reserve0, decimals);
      const secondToken = parseBNumber(assets._reserve1, decimals);
      setPoolInfo({
        bteToken,
        secondToken,
        constantProduct: bteToken * secondToken
      });

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, account, provider])

  useEffect(() => {
    initProcess();
  }, [initProcess])

  const getPriceImpact = (amount) => {
    const marketRatio = poolInfo.secondToken / poolInfo.bteToken;
    const newSecondToken = poolInfo.secondToken + amount;
    const newLiq = poolInfo.constantProduct / newSecondToken;
    const received = poolInfo.bteToken - newLiq;
    const secondTokenPerLiq = amount / received;
    const price_impact = (secondTokenPerLiq / marketRatio - 1) * 100;
    return price_impact;
  }

  const buyBte = async () => {
    if (loading) return;
    if (buyLoading) return;
    if (!account) return;
    if (fromAmount > balance || fromAmount <= 0.0) return;

    try {
      setStep(1);
      setBuyLoading(true);
      const buyContract = new Contract(chain.address, chain.abi, provider.getSigner(account));

      if (isInBte) {
        await buyContract.swapETHForExactTokens(
          amountOut,
          contractInfo.pathArray,
          account,
          Math.floor((new Date().getTime() + 1200000) / 1000),
          { from: account, value: amountIn }
        );
      } else {
        await buyContract.swapExactETHForTokens(
          amountOut,
          contractInfo.pathArray,
          account,
          Math.floor((new Date().getTime() + 1200000) / 1000),
          { from: account, value: amountIn }
        )
      }

      gaEventTracker('Buy');
      setBuyLoading(false);
      setStep(2);
    } catch (err) {
      setStep(3);
      console.log(err.code);
      if (err.code === 4001) {
        setStep(0);
      } else {
        setStep(3);
      }
      setBuyLoading(false);
    }
  }

  const handleFromToken = async (fromStr) => {
    if (loading) return;

    setIsInBte(false);
    const from = fromStr.replace(',', '.');
    const reg = /^\d+\.?\d*$/;

    if (fromStr === '') {
      setFromInput('');
      setFromAmount(0);
      setAmountIn(0.0);
      setAmountOut(0.0);
      setToInput('');
      setToAmount(0);
      setPriceImpact(0);
    } else if (reg.test(from)) {
      setFromInput(fromStr);
      setFromAmount(parseFloat(from));
      const amount_in = ethers.utils.parseUnits(from, 'ether');
      setAmountIn(amount_in);
      const price_impact = getPriceImpact(parseFloat(from));
      setPriceImpact(price_impact);
      try {
        const buyContract = new Contract(chain.address, chain.abi, provider);
        const amount_out = await buyContract.getAmountsOut(
          amount_in,
          contractInfo.pathArray
        );
        const amount_out_parse = parseBNumber(amount_out[1], decimals);
        // const amount_out_buffer = amount_out_parse * (1 - (price_impact + 0.1) / 100);
        const amount_out_buffer = amount_out_parse * 0.9985;
        const amount_out_min = ethers.utils.parseUnits(amount_out_buffer.toFixed(18), 'ether');
        setAmountOut(amount_out_min);
        setToInput(amount_out_parse.toFixed(2));
        setToAmount(amount_out_parse.toFixed(2));
      } catch (err) {
        console.log(err);
      }
    }
  }

  const handleToToken = async (toStr) => {
    if (loading) return;

    setIsInBte(true);
    const to = toStr.replace(',', '.');
    const reg = /^\d+\.?\d*$/;

    if (toStr === '') {
      setToInput('');
      setToAmount(0);
      setAmountOut(0.0);
      setAmountIn(0.0);
      setFromInput('');
      setFromAmount(0);
      setPriceImpact(0);
    } else if (reg.test(to)) {
      setToInput(toStr);
      setToAmount(parseFloat(to));
      const amount_out = ethers.utils.parseUnits(to, 'ether');
      setAmountOut(amount_out);
      try {
        const buyContract = new Contract(chain.address, chain.abi, provider);
        const amount_in = await buyContract.getAmountsIn(
          amount_out,
          contractInfo.pathArray
        );
        const amount_in_parse = parseBNumber(amount_in[0], decimals);
        const price_impact = getPriceImpact(amount_in_parse);
        setPriceImpact(price_impact);
        // const amount_in_buffer = amount_in_parse * (1 + (price_impact + 0.1) / 100);
        const amount_in_buffer = amount_in_parse * 1.0015;
        const amount_in_max = ethers.utils.parseUnits(amount_in_buffer.toFixed(18), 'ether');
        setAmountIn(amount_in_max);
        setFromInput(amount_in_parse.toFixed(4));
        setFromAmount(amount_in_parse.toFixed(4));
      } catch (err) {
        console.log(err);
      }
    }
  }

  return (
    <div className='popup-container'>
      <div className='w-full max-w-sm rounded-xl bg-secondary pt-8 pb-10 px-9 relative'>
        <div className='flex justify-between items-center pb-6'>
          <h4 className='text-15 text-gray-light font-medium'>
            {step === 0 && `${locale['buy']} BTE`}
            {step === 1 && locale['swapping_tokens']}
            {step === 2 && locale['swap_successful']}
            {step === 3 && locale['transaction_failed']}
          </h4>
          <div className='cursor-pointer'>
            <img
              src={CloseIcon}
              alt=""
              className='w-5 h-5'
              onClick={() => setOpenPopup(false)}
            />
          </div>
        </div>
        <div>
          {step === 0 && (
            <div>
              <div className='flex items-center justify-between text-12 text-gray-light pb-2.5'>
                <h5>{locale['from']}</h5>
                <h5>{balance.toFixed(4)}</h5>
              </div>
              <div className='border border-green-primary rounded-full flex items-center h-50px px-4.5'>
                <div className='flex items-center'>
                  <img src={netInfo[chainId]?.['image']} alt="" className='w-7 h-7' />
                  <h5 className='text-19 font-semibold mx-2 text-gray-light'>
                    {netInfo[chainId]?.['name']}
                  </h5>
                </div>
                <input
                  type="text"
                  inputMode="decimal"
                  className='border-none outline-none text-19 w-36 bg-transparent'
                  placeholder='0.00'
                  value={fromInput}
                  onChange={(e) => handleFromToken(e.target.value)}
                />
              </div>
              <p className='pl-5 pt-3 text-10 text-gray-light'>= ${(fromAmount * tokenPrice).toFixed(2)}</p>

              <div className='flex items-center justify-center mb-5'>
                <img src={ArrowDownIcon} alt="" className='w-4 h-5' />
              </div>

              <div className='flex items-center justify-between text-12 text-gray-light pb-2.5'>
                <h5>{locale['to']}</h5>
                <h5>{bteBalance.toFixed(4)}</h5>
              </div>
              <div className='border border-green-primary rounded-full flex items-center h-50px px-4.5'>
                <div className='flex items-center'>
                  <img src={BteIcon} alt="" className='w-7 h-7' />
                  <h5 className='text-19 font-semibold mx-2 text-gray-light'>BTE</h5>
                </div>
                <input
                  type="text"
                  inputMode="decimal"
                  className='border-none outline-none text-19 w-36 bg-transparent'
                  placeholder='0.00'
                  value={toInput}
                  onChange={(e) => handleToToken(e.target.value)}
                />
              </div>

              {priceImpact > 1 ? (
                <div className='flex justify-between items-center px-5 py-4'>
                  <p className='text-12 font-semibold text-gray-light'>Price Imapact High</p>
                  <p className='text-12 font-semibold text-red-400'>{priceImpact.toFixed(2)}%</p>
                </div>
              ) : (
                <div className='h-50px'></div>
              )}

              {account ? (
                <Button
                  type='contained'
                  className='h-50px'
                  onClick={() => buyBte()}
                >
                  <span>{locale['buy']} BTE</span>
                </Button>
              ) : (
                <Button
                  type='contained'
                  className='h-50px'
                  onClick={() => setOpenWalletPopup(true)}
                >
                  <span>{locale['connect_wallet']}</span>
                </Button>
              )}
            </div>
          )}
          {step === 1 && (
            <div>
              <div className='flex justify-center py-8'>
                <ClipLoader color={'#63C127'} size={80} />
              </div>
              <div className='flex justify-center py-12'>
                <SwapAmountPanel from={fromAmount} to={toAmount} netId={chainId} />
              </div>
              <Button
                type='contained'
                className='h-50px'
                onClick={() => setOpenPopup(false)}
              >
                <span>{locale['cancel']}</span>
              </Button>
            </div>
          )}
          {step === 2 && (
            <div>
              <div className='flex items-center justify-center relative'>
                <PuffLoader size={148} color={'#63C127'} />
                <div className='absolute top-6.5 flex justify-center items-center w-20 h-20 bg-green-primary rounded-full'>
                  <img src={CheckIcon} alt="" className='w-full h-full' />
                </div>
              </div>
              <div className='flex justify-center py-12'>
                <SwapAmountPanel from={fromAmount} to={toAmount} netId={chainId} />
              </div>
              <Button
                type='contained'
                className='h-50px'
                onClick={() => setOpenPopup(false)}
              >
                <span>{locale['done']}</span>
              </Button>
            </div>
          )}
          {step === 3 && (
            <div>
              <div className='pb-4'>
                <img src={WarningIcon} alt="" className='w-8 h-8'/>
              </div>
              <div>
                <p className='pb-5 text-12'>{locale['fail_message']}</p>
                <p className='text-12'>{locale['confirm_try_again']}</p>
              </div>
              <div className='flex justify-center py-12'>
                <SwapAmountPanel from={fromAmount} to={toAmount} netId={chainId} />
              </div>
              <Button
                type='contained'
                className='h-50px'
                onClick={() => setStep(0)}
              >
                <span>{locale['try_again']}</span>
              </Button>
            </div>
          )}
        </div>
      </div>
      {openWalletPopup && (
        <WalletPopup
          setOpenPopup={setOpenWalletPopup}
        />
      )}
    </div>
  )
}