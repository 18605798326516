import React, { useEffect, useState } from 'react';
import ReactSlider from "react-slider";
import ClipLoader from 'react-spinners/ClipLoader';
import PuffLoader from 'react-spinners/PuffLoader';
import PulseLoader from 'react-spinners/PulseLoader';
import { toast } from 'react-toastify';
import Button from '../Button';
import { getVestingFormat, readableNumber } from '../../services/utils';
import PercentIcon from '../../assets/images/percent-icon.svg';
import TimerIcon from '../../assets/images/timer-icon.svg';
import BteIcon from '../../assets/images/betero-icon.svg';
import BnbIcon from '../../assets/images/bnb-icon-new.svg';
import EthIcon from '../../assets/images/eth-token.png';
import CroIcon from '../../assets/images/cronos-pair.svg';
import CheckIcon from '../../assets/images/check-icon.svg';
import { useDispatch } from 'react-redux';
import { actions } from '../../redux/common.redux';
import BuyBTEPopup from '../BuyBTEPopup';
import WalletPopup from '../WalletPopup';
import useAnalyticsEventTracker from '../../services/useAnalyticsEventTracker';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Contract, ethers } from 'ethers';
import { useWeb3React } from '@web3-react/core';

const ICONLIST = {
  'BNB': BnbIcon,
  'ETH': EthIcon,
  'CRO': CroIcon
}

const TokenImages = ({contract}) => (
  <>
    {contract.type === 'MULTIPLE' ? (
      <div className='flex'>
        <img src={BteIcon} alt="" className='w-7 h-7 mb-2.5 z-10' />
        <img
          src={ICONLIST[contract.secondToken]}
          alt=""
          className='w-7 h-7 mt-2.5 -ml-2'
        />
      </div>
    ) : (
      <img src={BteIcon} alt="" className='w-10 h-10' />
    )}
  </>
)
const StakingForm = ({
  contract,
  userStakedToken,
  userBalance,
  btePrice,
  lpPrice,
  apy,
  userEarnedBTE,
  vesting,
  approved,
  formName,
  setFormName,
  withdrawable,
  dataLoading,
}) => {
  const {account, provider} = useWeb3React();
  const dispatch = useDispatch();
  const { locale, stakingAmount } = useSelector(state => state.common);

  const tokenName = contract.type === 'MULTIPLE' ? contract.name + ' LP' : contract.name;
  const balance = formName === 'DEPOSIT' ? userBalance : userStakedToken;
  // const balance = 200000.00
  const tokenPrice = contract.type === 'MULTIPLE' ? lpPrice : btePrice;

  const [stakeAmount, setStakeAmount] = useState(stakingAmount);
  const [stakeInput, setStakeInput] = useState('');
  const [step, setStep] = useState(0); //0-defalt 1-approving 2-depositing 3-finished
  const [openBuyPopup, setOpenBuyPopup] = useState(false);
  const [openWalletPopup, setOpenWalletPopup] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);

  const gaEventTracker = useAnalyticsEventTracker('Token');

  useEffect(() => {
    console.log('stakingAmount', stakingAmount);
    setStakeAmount(stakingAmount);
    setStakeInput('');
  }, [formName, userStakedToken, stakingAmount, dispatch])

  const stakeToken = async (amount) => {
    try {
      const farmContract = new Contract(contract.address, contract.abi, provider.getSigner(account));

      if (formName === 'DEPOSIT') {
        if (contract.type === 'MULTIPLE') {
          const tx = await farmContract.deposit(amount, account);
          await tx.wait();
        } else {
          const tx = await farmContract.deposit(amount);
          await tx.wait();
        }
      } else if (formName === 'WITHDRAW') {
        if (withdrawable) {
          if (contract.type === 'MULTIPLE') {
            const tx = await farmContract.withdraw(amount, account);
            await tx.wait();
          } else {
            const tx = await farmContract.withdraw(amount);
            await tx.wait();
          }
        } else {
          toast.error('You can\'t withdraw now', { autoClose: 3000, theme: 'colored' });
          setStep(0);
          return;
        }
      }
      gaEventTracker(`${formName}_${contract.eventLabel}`);
      dispatch(actions.changeRefresh());
      setStep(3);
    } catch (err) {
      toast.error('Failed the transaction', { autoClose: 3000, theme: 'colored' });
      setStep(0);
      console.log(err);
    }
  }

  const handleStake = async () => {
    if (userStakedToken === 0 && !agreeTerms) {
      // toast.error('Please agree to our Risk Discalimer and Terms & conditions', { autoClose: 3000, theme: 'colored' });
      return;
    }

    if (stakeAmount <= 0.0 || stakeAmount > balance) {
      toast.error('Invalid stake amount.', { autoClose: 3000, theme: 'colored' });
      return;
    }

    dispatch(actions.updateStakeAmount(stakeAmount));
    const amount = ethers.utils.parseUnits(stakeAmount.toString(), 'ether');
    const tokenContract = new Contract(contract.tokenAddress, contract.tokenAbi, provider.getSigner(account));
    const allowance = await tokenContract.allowance(account, contract.address); 
    if (Number(allowance) >= Number(amount)) {
      setStep(2);
      await stakeToken(amount);
    } else {
      setStep(1);
      tokenContract.approve(
        contract.address,
        '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
      )
        .on('receipt', async () => {
          gaEventTracker('Approve');
          setStep(2);
          await stakeToken(amount);
        })
        .on('error', () => {
          toast.error('Failed to approve token.', { autoClose: 3000, theme: 'colored' });
          setStep(0);
        });
    }
  }

  const handleChangeAmount = (amount) => {
    const reg1 = /^\d+\.?\d*$/;
    const reg2 = /^\d+,?\d*$/;
    if (reg1.test(amount) || reg2.test(amount)) {
      setStakeInput(amount);
      setStakeAmount(parseFloat(amount.replace(',', '.')));
    } else if (amount === '') {
      setStakeInput('');
      setStakeAmount(0);
    }
  }

  return (
    <div
      className='border-2 border-gray-dark rounded-xl px-4 md:px-5 pt-5 pb-6 h-full'
    >
      {!dataLoading ? (
        <>
          {step === 0 && (
            <div>
              <div className='flex justify-between items-center text-15 text-gray-light'>
                <p>{formName === 'DEPOSIT' ? locale['deposit'] : locale['withdraw']}</p>
                <p>
                  {locale['balance']} &nbsp;
                  <span className='text-white'>{readableNumber(balance.toFixed(2))}</span>
                </p>
              </div>
              <div className='flex items-center mb-4 mt-2.5'>
                <TokenImages contract={contract} />

                <div className='pl-3'>
                  <input
                    type="text"
                    inputMode="decimal"
                    className='bg-transparent outline-none border-none text-19 w-28'
                    placeholder='0.00'
                    value={stakeInput}
                    onChange={(e) => handleChangeAmount(e.target.value)}
                  />
                  <span className='text-19'>{tokenName}</span>
                  <p className='text-12 text-gray-light'>
                    ${(stakeAmount * tokenPrice).toFixed(2)}
                  </p>
                </div>
              </div>
              <div className='w-full mb-5'>
                <ReactSlider
                  className="horizontal-slider"
                  thumbClassName="example-thumb"
                  trackClassName="example-track"
                  renderThumb={(props, state) => <div {...props}>{readableNumber(state.valueNow / 100)}</div>}
                  min={0}
                  max={Math.floor(balance * 100)}
                  value={stakeAmount * 100}
                  onChange={(value) => {
                    setStakeAmount(value / 100);
                    setStakeInput(value / 100);
                  }}
                />
              </div>
              {formName === 'DEPOSIT' ? (
                <div>
                  <div className='flex justify-between mb-5'>
                    <div className='flex items-center'>
                      <img src={PercentIcon} alt="" className='w-38px h-38px' />
                      <div className='ml-2'>
                        <p className='text-12 text-gray-light'>{locale['earning_prognosis']}</p>
                        <h6 className='text-15 mt-1'>{apy.toFixed(2)}% APY</h6>
                      </div>
                    </div>
                    <div className='text-right'>
                      <p className='text-12 text-gray-light'>{locale['year']}</p>
                      <h6 className='text-15 mt-1'>
                        ${readableNumber((stakeAmount * tokenPrice * apy / 100).toFixed(2))}
                      </h6>
                    </div>
                  </div>
                  <div className='flex items-center pb-7.5'>
                    <img src={TimerIcon} alt="" className='w-38px h-38px' />
                    <div className='ml-2'>
                      <p className='text-12 text-gray-light'>{locale['vesting_period']}</p>
                      <h6 className='text-15 mt-1'>{getVestingFormat(vesting, locale)}</h6>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='h-10 md:h-139px'></div>
              )}
              {userStakedToken === 0 && (
                <div className='flex items-center pb-4'>
                  <div
                    className={classNames(
                      'w-4 h-4 rounded-sm',
                      { 'bg-green-primary': agreeTerms },
                      { 'bg-secondary border border-gray-light': !agreeTerms }
                    )}
                    onClick={() => setAgreeTerms(!agreeTerms)}
                  />
                  <p className='text-10 ml-2'>
                    {locale['i_agree_to_our']} &nbsp;
                    <span className='hover:text-green-primary underline'>
                      <a href="https://www.betero.io/risk_disclaimer.pdf" target={'_blank'} rel="noreferrer">
                        {locale['risk_disclaimer']}
                      </a>
                    </span> &nbsp;
                    {locale['and']} &nbsp;
                    <span className='hover:text-green-primary underline'>
                      <a href="https://www.betero.io/terms.pdf" target={'_blank'} rel="noreferrer">
                        {locale['terms']}
                      </a>
                    </span>
                  </p>
                </div>
              )}
              <div className='flex justify-center'>
                {formName === 'DEPOSIT' && (
                <div className='mr-1.5 w-1/2'>
                  {contract.name === 'ETH-BTE' || contract.name === 'CRO-BTE' ? (
                    <Button type='outlined' compact className='h-50px' url={contract.addLiquidityLink}>
                      <span className='text-center'>{locale['add_liquidity']}</span>
                    </Button>
                  ) : (
                    <Button type='outlined' compact className='h-50px' onClick={() => setOpenBuyPopup(true)}>
                      {locale['buy']} BTE
                    </Button>
                  )}
                </div>)}
                <div className='ml-1.5 w-1/2'>
                  {account ? (
                    <Button
                      type='contained'
                      compact
                      nonHover={userStakedToken === 0 && !agreeTerms}
                      className={`h-50px ${(userStakedToken === 0 && !agreeTerms) ? 'opacity-50' : ''}`}
                      onClick={() => handleStake()}
                    >
                      <span className='text-center'>{formName === 'DEPOSIT' ? locale['deposit_and_earn'] : locale['withdraw']}</span>
                    </Button>
                  ) : (
                    <Button
                      type='contained'
                      className='h-50px'
                      onClick={() => setOpenWalletPopup(true)}
                    >
                      <span className='text-center'>{locale['connect_wallet']}</span>
                    </Button>
                  )}
                </div>
              </div>
            </div>
          )}
          {(step === 1 || step === 2) && (
            <div className='flex flex-col justify-between h-full'>
              <div>
                <p className='text-15 text-gray-light mb-8'>
                  {step === 1
                    ? locale['approve_tokens']
                    : formName === 'DEPOSIT'
                      ? locale['deposit_tokens']
                      : locale['withdraw_tokens']}
                </p>
                <div className='flex items-cente justify-center mb-5'>
                  <ClipLoader size={76} color={'#63C127'} />
                </div>
                <p className='text-15 text-center mb-7'>
                  {step === 1
                    ? locale['approve_tokens_to_enable_farming']
                    : formName === 'DEPOSIT'
                      ? locale['deposit_bte_to_farming_contract']
                      : locale['withdraw_bte']}
                </p>
                <div className='flex justify-center items-center'>
                  <TokenImages contract={contract} />
                  <div className='pl-3'>
                    <h5 className='text-19 font-semibold leading-none'>
                      {readableNumber(stakeAmount)} {tokenName}
                    </h5>
                    <p className='text-12 text-gray-light'>
                      ${readableNumber((stakeAmount * tokenPrice).toFixed(2))}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <Button
                  type='outlined'
                  className='h-50px mt-8'
                  onClick={() => setStep(0)}
                >
                  {locale['cancel']}
                </Button>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className='flex flex-col justify-between h-full'>
              <div>
                <p className='text-15 text-gray-light text-medium mb-4'>{locale['success']}</p>
                <div className='flex items-center justify-center relative'>
                  <PuffLoader size={116} color={'#63C127'} />
                  <div className='absolute top-6.5 flex justify-center items-center w-16 h-16 bg-green-primary rounded-full'>
                    <img src={CheckIcon} alt="" className='w-full h-full' />
                  </div>
                </div>
                <p className='text-15 text-center mb-7'>
                  {formName === 'DEPOSIT' ? locale['deposited'] : locale['withdrawn']}
                </p>
                <div className='flex justify-center items-center'>
                  <TokenImages contract={contract} />
                  <div className='pl-3'>
                    <h5 className='text-19 font-semibold leading-none'>
                      {readableNumber(stakeAmount)} {tokenName}
                    </h5>
                    <p className='text-12 text-gray-light'>
                      ${readableNumber((stakeAmount * tokenPrice).toFixed(2))}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <Button
                  type='outlined'
                  className='h-50px mt-8'
                  onClick={() => {
                    setStep(0);
                    setStakeAmount(0);
                    setStakeInput('');
                    setFormName('DEPOSIT');
                    dispatch(actions.updateStakeAmount(0));
                    // dispatch(actions.changeRefresh());
                  }}
                >
                  {locale['deposit_more']}
                </Button>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className='w-full h-full flex items-center justify-center'>
          <PulseLoader size={15} color={'#474851'} />
        </div>
      )}

      {openBuyPopup && (
        <BuyBTEPopup
          setOpenPopup={setOpenBuyPopup}
        />
      )}

      {openWalletPopup && (
        <WalletPopup
          setOpenPopup={setOpenWalletPopup}
        />
      )}
    </div>
  )
}

export default StakingForm;